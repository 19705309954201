<script lang="ts">
	import { getTranslate, T } from '@tolgee/svelte';
	import {
		NOTIFICATION,
		NOTIFICATION_DURATION,
		notificationStore
	} from '$lib/store/notification.store';
	import { field, form } from 'svelte-forms';
	import { createSubscription, type KlaviyoList } from '$lib/klaviyo';
	import FormFieldCheckbox from '$lib/components/Form/FormFieldCheckbox.svelte';
	import FormFieldTextInput from '$lib/components/Form/FormFieldTextInput.svelte';
	import ButtonPrimary from '$lib/components/Button/ButtonPrimary.svelte';
	import ButtonSecondary from '$lib/components/Button/ButtonSecondary.svelte';

	export let show = false;
	export let titleKey: string;
	export let klaviyoList: KlaviyoList;
	export let successMessage: string | undefined = undefined;
	export let failureMessage: string | undefined = undefined;
	export let subtitleKey: string | undefined = undefined;
	export let descriptionKey: string | undefined = undefined;
	export let emailLabel: string | undefined = undefined;
	export let ignoreBtnKey: string | undefined = undefined;
	export let waitBtnKey: string | undefined = undefined;

	let showForm = true;
	let success = false;
	let failure = false;

	const { t } = getTranslate();
	const showVerifyEmail = () =>
		notificationStore.addNotification({
			hideAfter: NOTIFICATION_DURATION.LONG,
			text: $t('modals.newsletterSettings.confirm.content'),
			title: $t('modals.newsletterSettings.confirm.title'),
			variant: NOTIFICATION.ALERT
		});

	const [equineCheckbox, dogCheckbox, boatCheckbox, email] = [
		field('listEquine', true),
		field('listDog', true),
		field('listBoat', true),
		field('email', '')
	];
	const newsletterForm = form(equineCheckbox, dogCheckbox, boatCheckbox, email);
	$: errors = $newsletterForm.errors;

	const handleSubmit = async () => {
		const isEverythingSelected =
			!$dogCheckbox.value && !$boatCheckbox.value && !$equineCheckbox.value;
		try {
			const response = await createSubscription($email.value, klaviyoList, {
				listDog: isEverythingSelected || $dogCheckbox.value,
				listBoat: isEverythingSelected || $boatCheckbox.value,
				listEquine: isEverythingSelected || $equineCheckbox.value
			});
			if (response.status !== 202) {
				failure = true;
				success = false;
				showVerifyEmail();
			} else {
				success = true;
				failure = false;
				showForm = false;
			}
		} catch {
			failure = true;
			success = false;
		}
	};
</script>

{#if show}
	<div
		class="bg-dark-tertiary items-center fixed top-0 left-0 right-0 bottom-0 z-[9999] flex justify-center"
	>
		<div class="bg-white p-10 rounded-xl shadow-l2 flex flex-col max-w-[30rem]">
			<p class="text-h-24 uppercase mb-2">
				<T keyName={titleKey} />
			</p>
			<p class="text-h-14 uppercase mb-1">
				{#if subtitleKey}
					<T keyName={subtitleKey} />
				{/if}
			</p>
			<p class="text-p-14 mb-6">
				{#if descriptionKey}
					<T keyName={descriptionKey} />
				{/if}
			</p>

			{#if showForm}
				<form class="w-full" on:submit|preventDefault={handleSubmit}>
					<div class="relative">
						<div class="text-accent-primary text-p-12 mb-2">{$t('newsletter.list.title')}</div>
						<div class="flex gap-8 mb-2">
							<FormFieldCheckbox {errors} name="listEquine" bind:checked={$equineCheckbox.value}>
								{$t('newsletter.list.equine')}
							</FormFieldCheckbox>
							<FormFieldCheckbox {errors} name="listDog" bind:checked={$dogCheckbox.value}>
								{$t('newsletter.list.dog')}
							</FormFieldCheckbox>
							<FormFieldCheckbox {errors} name="listBoat" bind:checked={$boatCheckbox.value}>
								{$t('newsletter.list.boat')}
							</FormFieldCheckbox>
						</div>
						{#if emailLabel}
							<FormFieldTextInput
								class="mt-6 md:mt-0"
								label={$t(emailLabel)}
								type="email"
								name="email"
								bind:value={$email.value}
								{errors}
								required
							/>
						{/if}
					</div>
					{#if waitBtnKey && !success}
						<ButtonPrimary class="my-4 w-full" text={$t(waitBtnKey)} type="submit" />
					{/if}
				</form>
			{/if}
			{#if ignoreBtnKey && !success}
				<ButtonSecondary
					class="w-full"
					on:click={() => {
						show = false;
					}}
					text={$t(ignoreBtnKey)}
				/>
			{/if}
			{#if success}
				<p class="text-dark-secondary font-bold text-h-16 w-full justify-center mb-2 flex gap-2">
					<span class="text-special-success w-6 h-6 i-sprenger-check" />
					{#if successMessage}
						<T keyName={successMessage} />
					{/if}
				</p>
			{/if}

			{#if failure}
				<p class="text-dark-secondary font-bold text-h-16 w-full justify-center mt-2 flex gap-2">
					<span class="text-special-danger w-6 h-6 i-sprenger-x" />
					{#if failureMessage}
						<T keyName={failureMessage} />
					{/if}
				</p>
			{/if}
			{#if ignoreBtnKey && success}
				<ButtonSecondary
					on:click={() => {
						show = false;
					}}
					text={$t(ignoreBtnKey)}
				/>
			{/if}
		</div>
	</div>
{/if}
